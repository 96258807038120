
import {computed, defineComponent} from "vue";
import store from "@/store";
import Row from "@/components/base/common/Row.vue";
import Contacts from "@/views/contact/Contacts.vue";

export default defineComponent({
  name: "Overview",
  components: {Contacts, Row},
  setup() {
    const company = computed(() => store.state.CompanyModule.company);
    return {
      company,
    }
  }
})
